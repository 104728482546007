import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Header from "./components/general/Header";
import Footer from "./components/general/Footer";
// import NavBar from './components/general/NavBar'
// import Whatsapp from './components/general/Whatsapp'
import { useSelector } from "react-redux";
import {
  isSessionSuccess,
  selectSessionStatus,
} from "./StateManagement/sessionSlice";
import useGaTracker from "./useGaTracker";

//PAGES
import Loading from "./components/general/Loading";
import Forms from "./pages/Forms";
import ComingSoon from "./pages/ComingSoon";
import WelcomeTryout from "./pages/product/WelcomeTryout";
import LoginFirst from "./components/general/LoginFirst";

const Landingpage = lazy(() => import("./pages/Landingpage"));
const Login = lazy(() => import("./pages/Login"));
const Private = lazy(() => import("./pages/Private"));
const Pendaftaran = lazy(() => import("./pages/Pendaftaran"));
const Soal = lazy(() => import("./pages/Soal"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
// const Produk = lazy(() => import("./pages/Produk"));
const Product = lazy(() => import("./pages/Product"));
const Keranjang = lazy(() => import("./pages/Keranjang"));
const TentangKami = lazy(() => import("./pages/TentangKami"));
// const Toko = lazy(() => import("./pages/Toko"));
const Transaksi = lazy(() => import("./pages/Transaksi"));
const Pembayaran = lazy(() => import("./pages/users/Pembayaran"));

function App() {
  return (
    <React.Fragment>
      <Suspense fallback={<Loading />}>
        {/* <NavBar /> */}
        <Header />
        <AppRoutes />
        <Footer />
        {/* <Whatsapp /> */}
      </Suspense>
    </React.Fragment>
  );
}

function AppRoutes() {
  useGaTracker();
  const isLoggedIn = useSelector(isSessionSuccess);
  const status = useSelector(selectSessionStatus);

  if (isLoggedIn) {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/soal/:id" component={Soal} />
          <Route path="/login">
            <Redirect to="/" />
          </Route>
          <Route path="/forms/:id" component={Forms} />
          <Route path="/product" component={Product} />
          <Route path="/keranjang" component={Keranjang} />
          <Route path="/tentang-kami" component={TentangKami} />
          <Route path="/forms/:id" component={Forms} />
          <Route path="/coming-soon" component={ComingSoon} />
          <Route path="/sharing/:shortUrl" component={Landingpage} />
          <Route path="/welcome-tryout" component={WelcomeTryout} />
          <Route path="/toko" component={ComingSoon} />
          <Route path="/transaksi" component={Transaksi} />
          <Route path="/pembayaran/:type" component={Pembayaran} />
          <Route path="/" component={Private} />
        </Switch>
      </React.Fragment>
    );
  } else if (!isLoggedIn && status !== "idle") {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/" component={Landingpage} exact={true} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/login" component={Login} />
          <Route path="/pendaftaran" component={Pendaftaran} />
          <Route path="/product" component={Product} />
          <Route path="/keranjang" component={Keranjang} />
          <Route path="/tentang-kami" component={TentangKami} />
          <Route path="/forms/:id" component={Forms} />
          <Route path="/coming-soon" component={ComingSoon} />
          <Route path="/sharing/:shortUrl" component={Landingpage} />
          <Route path="/welcome-tryout" component={WelcomeTryout} />
          <Route path="/toko" component={ComingSoon} />
          <Route path="/transaksi" component={Transaksi} />
          <Route path="/pembayaran/:type" component={Pembayaran} />
          <Route component={LoginFirst} />
        </Switch>
      </React.Fragment>
    );
  } else {
    return <Loading />;
  }
}

export default App;
